export const CREATE = 'CREATE';
export const DELETE = 'DELETE';


// AUTH
export const UPDATE_AUTH_INPUT = 'UPDATE_AUTH_INPUT';
export const RESET_AUTH_INPUT = 'RESET_AUTH_INPUT';
// export const CLEAR = 'CLEAR';

export const LOG = 'LOG';
export const LOGOUT = 'LOGOUT';


// ID
export const ADD_ID = 'ADD_ID';


//SEARCH
export const ADD_SEARCH_QUESTION = 'ADD_SEARCH_QUESTION';
export const ADD_SEARCH_TOPIC = 'ADD_SEARCH_TOPIC';
export const ADD_SEARCH_CHANNEL = 'ADD_SEARCH_CHANNEL';

export const UPDATE_SEARCHED = 'UPDATE_SEARCHED';
export const LOCK_SEARCHED = 'LOCK_SEARCHED';
export const UNLOCK_SEARCHED = 'UNLOCK_SEARCHED';

export const UPDATE_SEARCH_INPUT = 'UPDATE_SEARCH_INPUT';


// POSTS
export const SET_POST = 'SET_POST';
export const ADD_POST = 'ADD_POST';
export const APPEND_POST = 'APPEND_POST';
export const FILL_POST = 'FILL_POST';
export const ADD_ANSWER = 'ADD_ANSWER';

export const ADD_CHILD_COMMENTS = 'ADD_CHILD_COMMENTS';